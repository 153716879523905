import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/chatp/Projects/farming-season/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "ทำไมเราถึงใช้-off-chain-"
    }}>{`ทำไมเราถึงใช้ Off-Chain ?`}</h1>
    <hr></hr>
    <p>{`ถ้าคุณเคยเล่นเกม Crypto ที่เป็น On-Chain มาก่อน การมาเล่น Farming Season นั้น คุณจะแปลกใจแน่ ๆ ว่าทำไมไม่มีการจ่ายค่า Gas เวลาเล่นเกมเลย นั่นเป็นเพราะว่าในส่วนเกมเพลย์ของเราเก็บข้อมูลแบบ Off-Chain`}</p>
    <p>{`เราต้องการให้เกม Farming Season เป็นเกมที่เป็นมิตรต่อรายจ่าย และผู้เล่นหน้าใหม่ เราไม่อยากให้ผู้เล่นเสียค่า Gas เยอะจนอาจทำให้หมดสนุกได้
เราจึงตัดสินใจเก็บข้อมูลบางส่วนไว้แบบ Off-Chain ในบางธุรกรรมภายในเกมอาจตรวจสอบไม่ได้ทั้งหมด แต่มันจะมอบประสบการณ์การเล่นที่ดีกว่าการจ่ายค่า Gas ทุกขั้นตอนอย่างแน่นอน`}</p>
    <p>{`แต่ไม่ต้องเป็นห่วงไป ธุรกรรมที่สำคัญในหน้าแดชบอร์ด เช่น การซื้อ การฝาก การถอน ทั้งหมดนี้คุณสามารถตรวจสอบ Transaction ID ได้ทางเว็บไซต์ Scanner ได้เลย`}</p>
    <p>{`และเนื่องจากตัวเกมเก็บข้อมูลแบบ Off-Chain คุณจึงสามารถเข้าเล่นเกมในสมาร์ทโฟนได้ โดยที่ไม่ต้องเชื่อมต่อ Metamask อีกด้วย !`}</p>
    <blockquote>
      <p parentName="blockquote">{`ปล. ถ้าไม่ได้เชื่อมต่อกระเป๋าคริปโตเอาไว้ สามารถใช้บัญชีและรหัสผ่านที่สร้างจากหน้าแดชบอร์ดเข้าสู่เกมได้`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      